import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../index/index'

Vue.use(VueRouter)
const routes = [{
        path: '/',
        redirect: '/index/index'
    },
    {
        path: '/',
        component: home,
        children: [{
                path: 'index/index',
                name: 'index',
                component: () =>
                    import ('../pages/index/index'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'goodsDetail/goodsDetail/:id',
                name: 'goodsDetail',
                component: () =>
                    import ('../pages/goodsDetail/goodsDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'auctionShow/auctionShow',
                name: 'auctionShow',
                component: () =>
                    import ('../pages/auctionShow/auctionShow'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'salesRank/salesRank',
                name: 'salesRank',
                component: () =>
                    import ('../pages/salesRank/salesRank'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'customerService/customerService',
                name: 'customerService',
                component: () =>
                    import ('../pages/customerService/customerService'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'competition/competition',
                name: 'competition',
                component: () =>
                    import ('../pages/competition/competition'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'farmMarket/farmMarket',
                name: 'farmMarket',
                component: () =>
                    import ('../pages/farmMarket/farmMarket'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'news/news',
                name: 'news',
                component: () =>
                    import ('../pages/news/news'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'gongchonglu/gongchonglu',
                name: 'gongchonglu',
                component: () =>
                    import ('../pages/gongchonglu/gongchonglu'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'gclDetail/gclDetail/:id',
                name: 'gclDetail',
                component: () =>
                    import ('../pages/gclDetail/gclDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'articleDetail/articleDetail/:id',
                name: 'articleDetail',
                component: () =>
                    import ('../pages/articleDetail/articleDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'articleDetail/brticleDetail/:id',
                name: 'brticleDetail',
                component: () =>
                    import ('../pages/articleDetail/brticleDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'articleDetail/crticleDetail/:id',
                name: 'crticleDetail',
                component: () =>
                    import ('../pages/articleDetail/crticleDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'goodsShow/goodsShow/:type',
                name: 'goodsShow',
                component: () =>
                    import ('../pages/goodsShow/goodsShow'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'categoryShow/categoryShow/:id/:name',
                name: 'categoryShow',
                component: () =>
                    import ('../pages/categoryShow/categoryShow'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
            {
                path: 'shopDetail/shopDetail/:id',
                name: 'shopDetail',
                component: () =>
                    import ('../pages/shopDetail/shopDetail'),
                meta: {
                    title: '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！',
                    keepAlive: false
                }
            },
        ]
    },
    {
        path: '/search/search/:kwd/:type',
        name: 'search',
        component: () =>
            import ('../pages/search/search'),
        meta: {
            keepAlive: false // 需要缓存
        }
    },
]

const router = new VueRouter({
    routes,
    // 解决路由跳转页面时，位置保持在上一页面位置的问题
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
})

router.beforeEach((to, from, next) => {
    // if (to.meta.title) {
    //     document.title = to.meta.title
    // }
    next()
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router