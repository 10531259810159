import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './request/api'
import ElementUI from 'element-ui';
import VideoPlayer from 'vue-video-player'
import commons from './common/utility.js'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
import 'element-ui/lib/theme-chalk/index.css';
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.common = commons
Vue.use(ElementUI);

import mixin from '@/common/mixin.js'
Vue.mixin(mixin)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')