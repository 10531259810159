// import base from './base'; // 导入接口域名列表
// import axios from './http'; // 导入http中创建的axios实例
import axios from 'axios';
import qs from 'qs'; // 根据需求是否导入qs模块
import {get, post } from './http'

const api = {
    // 新闻列表    
    articleList() {
        return axios.get(`${base.sq}/topics`);
    },
    // 新闻详情,演示    
    articleDetail(id, params) {
        return axios.get(`${base.sq}/topic/${id}`, {
            params: params
        });
    },
    // post提交    
    login(params) {
        return axios.post(`${base.sq}/accesstoken`, qs.stringify(params));
    },
    // 其他接口…………
    formmarketdetail(data) { //产地市场
        return get(`&r=api/articles/formmarketdetail` + `&${data}`)
    },

    gamedetail(data) { //全国赛事
        return get(`&r=api/articles/gamedetail` + `&${data}`)
    },

    searchList(data) {
        return get(`&r=api/articles/search-list` + `&${data}`)
    },

    IndexCarousel() {
        return get('&r=api/index/index')
    },
    CategoryList() {
        return get('&r=api/goods/category')
    },
    IndexGoods(data) {
        return get(`&r=api/index/goods-ajax-list` + `&${data}`)
    },
    TagList(data) {
        return get(`&r=api/goods/tag-list` + `&${data}`)
    },
    ListIndex(data) {
        return get(`&r=api/kuaidian/listindex` + `&${data}`)
    },
    BondList(data) {
        return get(`&r=api/goods/bondlist` + `&${data}`)
    },
    // 商品详情
    GoodsDetail(data) {
        return get(`&r=api/goods/detail` + `&${data}`)
    },
    // 店铺拍品
    ShopGoods(data) {
        return get(`&r=api/shop/shop-ajax-list` + `&${data}`)
    },
    NewsTitle(data) {
        return get(`&r=api/articles/index` + `&${data}`)
    },
    ArticleList(data) {
        return post(`&r=api/articles/get-list` + `&${data}`)
    },
    // 功虫录
    GclList(data) {
        return post(`&r=api/index/bugachlist` + `&${data}`)
    },
    customLink(data) {
        return get(`&r=api/index/custom-link` + `&${data}`)
    },
    // 功虫录详情
    GclDetail(data) {
        return get(`&r=api/index/bugachdetail` + `&${data}`)
    },
    // 全部拍品
    AllAuction(data) {
        return get(`&r=api/goods/search-keyword-api` + `&${data}`)
    },
    // 搜索
    SearchKeyword(data) {
        return post(`&r=api/articles/search-keyword-api` + `&${data}`)
    },
    // 商家
    searchShops(data) {
        return get(`&r=api/shop/search-shops-api` + `&${data}`)
    },
    // 拍品
    searchGoods(data) {
        return get(`&r=api/goods/search-keyword-api` + `&${data}`)
    },
    // 文章
    searchArticles(data) {
        return get(`&r=api/articles/search-keyword-api` + `&${data}`)
    },
    // 咨询详情
    ArticleDetail(data) {
        return get(`&r=api/articles/detail` + `&${data}`)
    },
    // 全国赛事
    CompetitionList(data) {
        return post(`&r=api/articles/formmarket` + `&${data}`)
    },
    // 产地市场
    formmarket(data) {
        return get(`&r=api/articles/gamelist` + `&${data}`)
    },
    //  销量排行
    SalesRank(data) {
        return get(`&r=api/goods/shoptotal`)
    },
    //  分类商品展示
    CategoryShow(data) {
        return get(`&r=api/goods/get-goods-type-detail-api` + `&${data}`)
    },
    // 店铺详情
    ShopDetail(data) {
        return get(`&r=api/index/seller-shop` + `&${data}`)
    },
    // 店铺商品详情
    ShopGoods(data) {
        return get(`&r=api/shop/shop-ajax-list` + `&${data}`)
    },
    // 售后服务
    SalesService(data) {
        return get(`&r=api/emp/search`)
    }

    // NewestGoods(data) {
    //     return get(`&r=api/index/dszx` + `&${data}`)
    // },
}

export default api;