import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loginStatus: !!0,
        token: '',
        user: '',
        navs: [],
        site: null,
        set: null,
        kw: [],
        default_img: '',
        fs: 0,
        newMsg: !!0,
        shop: null,
        config: null,
        plugin: null,
        imLogin: false,
        IM_USER: null,
    },
    getters: {
        loginStatus: state => {
            return state.loginStatus ? state.loginStatus : !!0;
        },
        token: state => {
            return state.token ? state.token : (uni.getStorageSync('token') ? uni.getStorageSync('token') : '');
        },
        user: state => {
            return state.user ? state.user : (uni.getStorageSync('user') ? uni.getStorageSync('user') : null);
        },
        shop: state => {
            return state.shop ? state.shop : (uni.getStorageSync('shop') ? uni.getStorageSync('shop') : null);
        },
        navs: state => {
            return state.navs.length > 0 ? state.navs : (uni.getStorageSync('navs') ? uni.getStorageSync('navs') : []);
        },
        site: state => {
            return state.site ? state.site : (uni.getStorageSync('site') ? uni.getStorageSync('site') : null);
        },
        set: state => {
            return state.set ? state.set : (uni.getStorageSync('set') ? uni.getStorageSync('set') : null);
        },
        kw: state => {
            return state.kw ? state.kw : (uni.getStorageSync('kw') ? uni.getStorageSync('kw') : []);
        },
        default_img: state => {
            return state.default_img ? state.default_img : (uni.getStorageSync('default_img') ? uni.getStorageSync(
                'default_img') : null);
        },
        fs: state => {
            return state.fs ? state.fs : (uni.getStorageSync('fs') ? uni.getStorageSync('fs') : 0);
        },
        newMsg: state => {
            return state.newMsg
        },
        config: state => {
            return state.config ? state.config : (uni.getStorageSync('config') ? uni.getStorageSync('config') :
                null);
        },
        plugin: state => {
            return state.plugin ? state.plugin : (uni.getStorageSync('plugin') ? uni.getStorageSync('plugin') :
                null);
        },
        imLogin: state => {
            return state.imLogin
        },
        IM_USER: state => {
            return state.IM_USER
        }

    },
    mutations: {
        setLogin(state) {
            state.loginStatus = !!uni.getStorageSync('token');
        },
        login(state, data) {
            state.token = data.token;
            state.user = data.user;
            state.fs = data.fs;
            uni.setStorageSync('token', data.token);
            uni.setStorageSync('user', data.user);
            uni.setStorageSync('fs', data.fs);
            this.commit('setLogin')
            uni.removeStorageSync('openid');
            // #ifdef H5 || MP-WEIXIN
            uni.removeStorageSync('code');
            uni.removeStorageSync('avatar');
            uni.removeStorageSync('nick_name');
            // #endif
            setTimeout(() => {
                // #ifdef H5
                uni.removeStorageSync('pid');
                if (uni.getStorageSync('isWeiXin')) window.location.replace(window.location.href.split('h5')[0] +
                    'h5/?uniacid=' + uni.getStorageSync('uniacid'));
                else router.back();
                // #endif
                // #ifdef MP-WEIXIN || APP-PLUS
                let prev_path = uni.getStorageSync('prev_path');
                if (prev_path) {
                    router.replace(prev_path)
                } else {
                    router.back()
                }
                // #endif
            }, 1500)
        },
        logout(state) {
            state.token = '';
            state.fs = 0;
            state.user = '';
            uni.removeStorageSync('token');
            uni.removeStorageSync('fs');
            uni.removeStorageSync('user');
            this.commit('setLogin')
            uni.hideLoading();
            // #ifdef APP-PLUS || MP-WEIXIN
            router.replaceAll('/pages/index/index')
                // #endif
                // #ifdef H5
            window.location.replace(window.location.href.split('h5')[0] + 'h5/?uniacid=' + uni.getStorageSync('uniacid'));
            // #endif
        },
        setGlobalData(state, data) {
            state.navs = data.navs;
            state.set = data.set;
            state.site = data.site
            state.default_img = data.img;
            state.kw = data.kw;
            state.config = data.config;
            uni.setStorageSync('site', data.site)
            uni.setStorageSync('navs', data.navs)
            uni.setStorageSync('set', data.set)
            uni.setStorageSync('default_img', data.img);
            uni.setStorageSync('kw', data.kw);
            uni.setStorageSync('config', data.config);
            state.plugin = data.plugin
            uni.setStorageSync('plugin', data.plugin)
            if (!uni.getStorageSync('is_launched')) {
                uni.setStorageSync('is_launched', true);
            }
            let newDate = new Date().getTime();
            uni.setStorageSync('updateTime', newDate)
        },
        hasNews(state, data) {
            state.newMsg = data
        },
        updateProfile(state, data) {
            state.user = data.user;
            state.fs = data.fs;
            uni.setStorageSync('user', data.user);
            uni.setStorageSync('fs', data.fs)
        },
        setShopInfo(state, data) {
            state.shop = data;
            uni.setStorageSync('shop', data)
        },
        imLogin(state, data) {
            state.imLogin = data.isLogin;
        },
        set_im_user(state, data) {
            state.IM_USER = data;
        }
    },
    actions: {},
    modules: {}
})