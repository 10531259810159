export default {
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    methods: {
        navigateTo(path) {
            this.$router.push({ path: path })
        },
        redirectTo(path) {
            this.$router.replace({ path: path })
        },
        pushTab(path) {
            this.$Router.pushTab(path)
        },
        reLauchTo(path = '/pages/index/index') {
            this.$Router.replaceAll(path)
        },
        goBack(n = 1) {
            this.$router.push({ path: path })
            this.$Router.back(n)
        },

    },

}