function setImgLocation(val) {
    if (val) {
        var imgLoaction = 'https://gzh.xs521.com';
        if (Array.isArray(val)) {
            val = val.map(res => {
                if (res.indexOf('http') === -1) {
                    return imgLoaction + '/' + res;
                } else {
                    return res
                }
            })
        } else if (val === "") {
            return val;
        } else {
            if (val.indexOf('http') === -1) {
                val = imgLoaction + '/' + val;
            }
        }
    }
    return val;
}

export default {
    setImgLocation,
}